// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_h2__wMUoe {
    font-size: 1.25rem;
    color: #eee;
    padding: 2rem 0 .5rem 1rem;
}

.Profile_nomeUser__uLqia {
    color: #97c01e;
}

.Profile_item0__sqEdQ {
    font-weight: bold;
    padding-left: 1rem;
    padding-right: .5rem;
}

.Profile_item0__sqEdQ, .Profile_item1__Z-ZUw {
    color: #ddd;
}

.Profile_lista__ebGe\\+ {
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/UserAccount/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".h2 {\n    font-size: 1.25rem;\n    color: #eee;\n    padding: 2rem 0 .5rem 1rem;\n}\n\n.nomeUser {\n    color: #97c01e;\n}\n\n.item0 {\n    font-weight: bold;\n    padding-left: 1rem;\n    padding-right: .5rem;\n}\n\n.item0, .item1 {\n    color: #ddd;\n}\n\n.lista {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h2": `Profile_h2__wMUoe`,
	"nomeUser": `Profile_nomeUser__uLqia`,
	"item0": `Profile_item0__sqEdQ`,
	"item1": `Profile_item1__Z-ZUw`,
	"lista": `Profile_lista__ebGe+`
};
export default ___CSS_LOADER_EXPORT___;
