// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_containerHome__Kahd- {
    display: grid;
    grid-template-columns: auto 4fr 1fr;
    background-color: #fafafa;
    overflow-y: scroll;
}

@media screen and (max-width: 900px)  {
    .Home_containerHome__Kahd- {
        display: flex;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mCAAmC;IACnC,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".containerHome {\n    display: grid;\n    grid-template-columns: auto 4fr 1fr;\n    background-color: #fafafa;\n    overflow-y: scroll;\n}\n\n@media screen and (max-width: 900px)  {\n    .containerHome {\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerHome": `Home_containerHome__Kahd-`
};
export default ___CSS_LOADER_EXPORT___;
