import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Input from "../Forms/Input";
import Button from "../Forms/Button";
import useForm from "../Hooks/useForm";
import { UserContext } from "../../UserContext";
import { Erro } from "../Erro";
import Head from "../Head";

import styles from "./LoginForm.module.css";
import stylesBtn from "../Forms/Forms.module.css";
import '../../App.css';

const LoginForm = () => {
  const username = useForm();
  const password = useForm();
  const { userLogin, erro, loading } = useContext(UserContext);

  async function handleSubmit(event) {
    event.preventDefault();

    if (username.validate() && password.validate()) {
      userLogin(username.value, password.value);
    }
  }

  return (
    <section className={`card ${styles.cardFlex}`}>
      <Head title="Login" description="Página de Login do UpSec." />

      <div className={styles.welcomeCard}>
        <h1 className="titulo">Bem-vindo</h1>
        <p className={styles.infoText}>
          Para se juntar a nós, por favor conecte-se com sua conta.
        </p>
        <div className={styles.imgLogin}>
          <img
            src="https://upsecvendas.com.br/wp-content/uploads/2023/05/cascata-logo-02.png"
            height="524px"
            width="457px"
            alt=""
            className={styles.imgPhoto}
          />
        </div>
      </div>

      <div className={styles.formCard}>
        <h2 className="subtitulo">Login</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input label="Usuário" type="text" name="username" {...username} />
          <Input label="Senha" type="password" name="password" {...password} />

          {loading ? (
            <Button disabled>Carregando</Button>
          ) : (
            <Button>Logar</Button>
          )}
          <Erro erro={erro} />
        </form>

        <div className={styles.cadastro}>
          <h2 className="subtitulo">Cadastrar</h2>
          <p>Ainda não possui conta? Se cadastre agora.</p>
          <Link className={stylesBtn.botao} to="/login/criar">
            Cadastro
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
