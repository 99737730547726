import React, { useContext, useState } from "react";
import Header from "../Header";
import styles from "./HeaderLP.module.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import RedesSociais from "../RedesSociais";

const HeaderLP = () => {
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const [menuOpen, setMenuOpen] = useState(false);

  async function handleClick(event) {
    event.preventDefault();
    return login ? navigate("/mapa") : navigate("/login");
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={styles.headerLP}>
      <Header>
        <label
          className={styles.menuButtonContainer}
          htmlFor="menuToggle"
          onClick={toggleMenu}
        >
          <div className={styles.menuButton}></div>
        </label>

        <ul className={`${styles.menu} ${menuOpen ? styles.menuOpen : ""}`}>
          <li onClick={handleClick}>{login ? `Ver mapa` : `Cadastrar/Login`}</li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linkedin.com/company/upsec/"
            >LinkedIn</a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@UpSecVendas/"
            >Youtube</a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/upsecvendas/"
            >Instagram</a>
          </li>
        </ul>

        <div className={styles.containerBtnSocial}>
          <RedesSociais />
          <button onClick={handleClick} className={styles.btnHeaderLP}>
            {login ? `Ver mapa` : `Cadastrar | Login`}
          </button>
        </div>
      </Header>
    </nav>
  );
};

export default HeaderLP;
