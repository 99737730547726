// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Forms_botao__6oesy {
    cursor: pointer;
    padding: .8rem 1.2rem;
    font-family: var(--fonte-principal);
    border: none;
    border-radius: .5rem;
    background-color: #3190cf;
    color: #eee;
    box-sizing: border-box;
    min-width: 8rem;
  }
  
  .Forms_botao__6oesy:hover, .Forms_botao__6oesy:focus {
    outline: none;
    box-shadow: 0 0 0 2px #3190cf;
    transition: .25s;
  }
  
  .Forms_botao__6oesy:disabled {
    opacity: .5;
    cursor: wait;
  }
  
  .Forms_input__BqPOp {
    padding: .25rem;
  }
  
  .Forms_inputwrapper__5X4up {
    margin-bottom: 1rem;
  }
  
  .Forms_input__BqPOp {
    border: 1px solid #eee;
    display: block;
    width: 100%;
    padding: .5rem;
    border-radius: .5rem;
    background-color: #eee;
  }
  
  .Forms_input__BqPOp:focus, .Forms_input__BqPOp:hover {
    outline: none;
    box-shadow: 0 0 0 2px  #104b87;
    background: #fff;
    transition: .25s;
  }
  
  .Forms_label__4ZUql {
    display: block;
    padding-top: .5rem;
    font-size: .8rem;
  }
  
  .Forms_erro__g2Ewn {
    color: #d42222;
    font-size: .87rem;
    margin-top: .25rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/Forms/Forms.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qBAAqB;IACrB,mCAAmC;IACnC,YAAY;IACZ,oBAAoB;IACpB,yBAAyB;IACzB,WAAW;IACX,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,cAAc;IACd,WAAW;IACX,cAAc;IACd,oBAAoB;IACpB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":[".botao {\n    cursor: pointer;\n    padding: .8rem 1.2rem;\n    font-family: var(--fonte-principal);\n    border: none;\n    border-radius: .5rem;\n    background-color: #3190cf;\n    color: #eee;\n    box-sizing: border-box;\n    min-width: 8rem;\n  }\n  \n  .botao:hover, .botao:focus {\n    outline: none;\n    box-shadow: 0 0 0 2px #3190cf;\n    transition: .25s;\n  }\n  \n  .botao:disabled {\n    opacity: .5;\n    cursor: wait;\n  }\n  \n  .input {\n    padding: .25rem;\n  }\n  \n  .inputwrapper {\n    margin-bottom: 1rem;\n  }\n  \n  .input {\n    border: 1px solid #eee;\n    display: block;\n    width: 100%;\n    padding: .5rem;\n    border-radius: .5rem;\n    background-color: #eee;\n  }\n  \n  .input:focus, .input:hover {\n    outline: none;\n    box-shadow: 0 0 0 2px  #104b87;\n    background: #fff;\n    transition: .25s;\n  }\n  \n  .label {\n    display: block;\n    padding-top: .5rem;\n    font-size: .8rem;\n  }\n  \n  .erro {\n    color: #d42222;\n    font-size: .87rem;\n    margin-top: .25rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botao": `Forms_botao__6oesy`,
	"input": `Forms_input__BqPOp`,
	"inputwrapper": `Forms_inputwrapper__5X4up`,
	"label": `Forms_label__4ZUql`,
	"erro": `Forms_erro__g2Ewn`
};
export default ___CSS_LOADER_EXPORT___;
