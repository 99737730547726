import React from 'react'
import styles from '../components/Dashboard/Sidebar.module.css';

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

function RedesSociais() {
  return (
    <ul className={styles.socialMedia}>
    <li>
      <a
        target="_blank" rel="noreferrer" href="https://linkedin.com/company/upsec/">
        <LinkedInIcon />
      </a>
    </li>
    <li>
      <a
        target="_blank" rel="noreferrer" href="https://www.youtube.com/@UpSecVendas">
        <YouTubeIcon />
      </a>
    </li>
    <li>
      <a
        target="_blank" rel="noreferrer" href="https://www.instagram.com/upsecvendas/">
        <InstagramIcon />
      </a>
    </li>
</ul>
  )
}

export default RedesSociais;