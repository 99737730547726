import React from "react";
import Maps from "./Maps";
import Lista from "./Lista";
import Sidebar from "./Sidebar";
import { Routes, Route } from "react-router-dom";
import Head from "../Head";
import Footer from "../Footer";
import styles from "./Home.module.css";

const Home = () => {

  return (
    <section className={styles.containerHome}>
      <Head title="Home" description="Home" />

      <Sidebar>
        <Routes>
          <Route path="/mapa" element={<Home />} />
        </Routes>
      </Sidebar>

      <Maps />
      <Lista />
    </section>
  );
};

export default Home;
