import React from "react";
import styles from "./Body.module.css";

const SectionCard = () => {
  return (
    <div>
      <section className={styles.sectionCardContainer}>
        <div className={styles.sectionSubCard}>
          <h2 className={styles.bodyH3}>Conheça os serviços disponíveis:</h2>
        </div>

        <div className={styles.sectionSubCard}>
          <p>
            Idealizada pela UpSec, esta plataforma foi criada para estimular o
            desenvolvimento do mercado de construção a seco. O que você está
            vendo é parte de um grande projeto em construção. Nossa plataforma
            foi pensada para conectar o distribuidor, a indústria, os
            instaladores de construção a seco e seus players em um único lugar.
            O que irá otimizar tempo e recurso de todos os envolvidos.
          </p>
        </div>
      </section>

      <section className={styles.sectionCard}>
        <div className={styles.cardContainer}>
          <span className={styles.cardTitulo}>Mapeamento de Mercado</span>
          <p className={styles.cardText}>
            Esta ferramenta possibilita identificar as empresas, de acordo com o
            interesse, e entender o potencial de mercado.
          </p>
        </div>

        <div className={styles.cardContainer}>
          <span className={styles.cardTitulo}>E-mail Marketing</span>
          <p className={styles.cardText}>
            De acordo com a necessidade do seu negócio e a realidade do mercado
            em que sua empresa está inserida.
          </p>
        </div>

        <div className={styles.cardContainer}>
          <span className={styles.cardTitulo}>Banner Digital</span>
          <p className={styles.cardText}>
            Divulgação da sua marca no único portal integrativo de construção a
            seco.
          </p>
        </div>

        <div className={styles.cardContainer}>
          <span className={styles.cardTitulo}>Pesquisa</span>
          <p className={styles.cardText}>
            Devido ao vasto mapeamento é possível contratar uma pesquisa para
            identificação de interesses do negócio, como percepção de marca,
            identificação de melhorias e necessidades de produtos, análise de
            concorrência, entre outra possibilidades.
          </p>
        </div>
      </section>
    </div>
  );
};

export default SectionCard;
