// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginCreate_cardFlex__uvoSY {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
  }

  .LoginCreate_redirect__drJWJ {
    display: flex;
    gap: .5rem;
    padding-top: 1rem;
  }

  .LoginCreate_aviso__MM0uD {
    font-size: .85rem;
  }

  p {
    padding: .5rem 0rem;
    color: #555;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Login/LoginCreate.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb","sourcesContent":[".cardFlex {\n    display: flex;\n    flex-direction: column;\n    padding: 4rem 1rem;\n  }\n\n  .redirect {\n    display: flex;\n    gap: .5rem;\n    padding-top: 1rem;\n  }\n\n  .aviso {\n    font-size: .85rem;\n  }\n\n  p {\n    padding: .5rem 0rem;\n    color: #555;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardFlex": `LoginCreate_cardFlex__uvoSY`,
	"redirect": `LoginCreate_redirect__drJWJ`,
	"aviso": `LoginCreate_aviso__MM0uD`
};
export default ___CSS_LOADER_EXPORT___;
