import React from "react";
import styles from "./Body.module.css";
import imgMapa from "../../assets/plataforma.png";

function SectionMapa() {
  return (
    <div className={styles.sectionMapa}>
      <div className={styles.mapWrap}>
        <img src={imgMapa} max-width="1286px" alt="imagem da página Mapa da Conexão Construtiva"
        className={styles.imgMapa}/>
      </div>

      <section className={styles.upsecInfoWrap}>
        <h3 className={styles.mapaH3}>Sobre</h3>
        <p>
          A plataforma Conexão Construtiva pertence a empresa UpSec Vendas que é
          uma empresa de consultoria de marketing e vendas para a construção
          civil.
        </p>
        <p>
          Suas fundadoras –&nbsp;Daniela Garcia e Pricila Correali&nbsp;–
          atuaram em empresas multinacionais e nacionais do segmento de
          construção, com participação direta em ações estratégicas
          desenvolvidas com os stakeholders. A plataforma é uma visualização de
          uma ferramenta poderosa que contribui ativamente para o
          desenvolvimento do mercado.
        </p>
      </section>
    </div>
  );
}

export default SectionMapa;
