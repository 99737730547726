// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Sidebar_sidebar__pty8z{
    background: rgba(16, 75, 135, 0.9);
    color: #ddd;
    height: 100vh;
    transition: all 0.5s;
}

.Sidebar_top_section__mUuwY {
    display: flex;
    align-items: center;
}

.Sidebar_bars__olKyH{
    display: flex;
    font-size: 1.5rem;
    margin-left: 3rem;
}

.Sidebar_link__fE\\+Gq{
    display: flex;
    color: #ddd;
    padding: .75rem 1rem;
    gap: 1rem;
    transition: all 0.5s;
}

.Sidebar_link__fE\\+Gq:hover{
    background:#3190cf;
    color: #222;
    transition: all 0.2s;
}

.Sidebar_active__5yY8g{
    background: #3190cf;
    color: #222;
}

.Sidebar_icon__5swqS, .Sidebar_link_text__7Z3Yb{
    font-size: 1rem;
    transition: all 0.5s;

}

.Sidebar_hamburguerMenu__IlcGc {
    cursor: pointer;
}

.Sidebar_sair__yO1D8 {
    color: #ddd;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 180px;
    height: 2rem;
    padding: 2rem;
}

.Sidebar_socialMedia__u42-l {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.25rem;
}

.Sidebar_socialMedia__u42-l a {
    color: #052c53;
    padding: 0 .25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Sidebar.module.css"],"names":[],"mappings":";;AAEA;IACI,kCAAkC;IAClC,WAAW;IACX,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,oBAAoB;IACpB,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,oBAAoB;;AAExB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["\n\n.sidebar{\n    background: rgba(16, 75, 135, 0.9);\n    color: #ddd;\n    height: 100vh;\n    transition: all 0.5s;\n}\n\n.top_section {\n    display: flex;\n    align-items: center;\n}\n\n.bars{\n    display: flex;\n    font-size: 1.5rem;\n    margin-left: 3rem;\n}\n\n.link{\n    display: flex;\n    color: #ddd;\n    padding: .75rem 1rem;\n    gap: 1rem;\n    transition: all 0.5s;\n}\n\n.link:hover{\n    background:#3190cf;\n    color: #222;\n    transition: all 0.2s;\n}\n\n.active{\n    background: #3190cf;\n    color: #222;\n}\n\n.icon, .link_text{\n    font-size: 1rem;\n    transition: all 0.5s;\n\n}\n\n.hamburguerMenu {\n    cursor: pointer;\n}\n\n.sair {\n    color: #ddd;\n    cursor: pointer;\n    position: absolute;\n    bottom: 0;\n    left: 180px;\n    height: 2rem;\n    padding: 2rem;\n}\n\n.socialMedia {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-top: 1.25rem;\n}\n\n.socialMedia a {\n    color: #052c53;\n    padding: 0 .25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__pty8z`,
	"top_section": `Sidebar_top_section__mUuwY`,
	"bars": `Sidebar_bars__olKyH`,
	"link": `Sidebar_link__fE+Gq`,
	"active": `Sidebar_active__5yY8g`,
	"icon": `Sidebar_icon__5swqS`,
	"link_text": `Sidebar_link_text__7Z3Yb`,
	"hamburguerMenu": `Sidebar_hamburguerMenu__IlcGc`,
	"sair": `Sidebar_sair__yO1D8`,
	"socialMedia": `Sidebar_socialMedia__u42-l`
};
export default ___CSS_LOADER_EXPORT___;
