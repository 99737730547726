import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import "../../App.css";
import styles from "./Sidebar.module.css";
import Profile from "../UserAccount/Profile";
import { UserContext } from "../../UserContext";

import logoPng from "../../assets/logo-new.png";
import RedesSociais from "../RedesSociais";

const Sidebar = ({ children }) => {
  const { userLogout } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/",
      name: "Home",
      icon: "home",
    },
    {
      path: "https://upsecvendas.com.br/quem-somos/",
      target: "_blank",
      name: "Sobre Nós",
      icon: "info",
    },
    {
      path: "https://upsecvendas.com.br/servicos-produtos/",
      target: "_blank",
      name: "Serviços e Produtos",
      icon: "edit_square",
    },
  ];

  return (
    <div>

      <div
        style={{ width: isOpen ? "17rem" : "3.5rem" }}
        className={styles.sidebar}
      >
        <div
          style={{ padding: isOpen ? "1rem 1rem 1.25rem 1rem" : "1.25rem 1rem" }}
          className={styles.top_section}
        >
          <h1
            style={{ display: isOpen ? "block" : "none" }}
            className={styles.logo}
          >
            <a href="/home">
              <img
                src={logoPng}
                alt=""
                width="166px"
                height="70px"
              />
            </a>
          </h1>
          <div
            style={{ marginLeft: isOpen ? "3rem" : "0px" }}
            className={styles.bars}
          >
            <span
              className={`material-symbols-outlined ${styles.hamburguerMenu}`}
              onClick={toggle}
            >
              menu
            </span>
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink target={item.target} to={item.path} key={index} className={styles.link}>
            <div className="material-symbols-outlined">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className={styles.link_text}
            >
              {item.name}
            </div>
          </NavLink>
        ))}

        <div
          style={{ display: isOpen ? "block" : "none" }}
          className={styles.link_text}
        >
          <Profile />
          
          <RedesSociais/>

          <footer>
            <p onClick={userLogout} className={styles.sair}>Sair</p>
          </footer>
        </div>
        
      </div>
      <section>{children}</section>
    </div>
  );
};

export default Sidebar;
