// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Maps_headerMaps__XxFA\\+ {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.Maps_tituloMaps__0yqx7 {
    font-size: 2rem;
    color: rgba(16, 75, 135, 0.9);
    margin-left: 2rem;
}

.Maps_filtro__xJHj\\+ {
    border-radius: .5rem;
    border: 2px solid rgba(16, 75, 135, 0.9);
    padding-left: 2rem;
    padding-right: 2rem;
}

.Maps_map__\\+DQOd {
    margin: 0 0 0 2rem;
    box-shadow: 0 6px 16px rgba(181,202,223,0.75);
    border-radius: .3rem;
}

.Maps_mapContainer__rIUTF {
    height: calc(100vh - 6rem);
    width: auto;
}

@media screen and (max-width: 1100px) {
    .Maps_tituloMaps__0yqx7 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    .Maps_map__\\+DQOd {
        margin: 0 2rem 0 2rem;
    }
    .Maps_headerMaps__XxFA\\+ {
        flex-wrap: wrap;
        justify-content: center;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Maps.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,6CAA6C;IAC7C,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,eAAe;QACf,uBAAuB;IAC3B;AACJ","sourcesContent":[".headerMaps {\n    display: flex;\n    justify-content: space-between;\n    padding: 1rem 0;\n}\n\n.tituloMaps {\n    font-size: 2rem;\n    color: rgba(16, 75, 135, 0.9);\n    margin-left: 2rem;\n}\n\n.filtro {\n    border-radius: .5rem;\n    border: 2px solid rgba(16, 75, 135, 0.9);\n    padding-left: 2rem;\n    padding-right: 2rem;\n}\n\n.map {\n    margin: 0 0 0 2rem;\n    box-shadow: 0 6px 16px rgba(181,202,223,0.75);\n    border-radius: .3rem;\n}\n\n.mapContainer {\n    height: calc(100vh - 6rem);\n    width: auto;\n}\n\n@media screen and (max-width: 1100px) {\n    .tituloMaps {\n        font-size: 1.5rem;\n    }\n}\n\n@media screen and (max-width: 768px) {\n    .map {\n        margin: 0 2rem 0 2rem;\n    }\n    .headerMaps {\n        flex-wrap: wrap;\n        justify-content: center;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerMaps": `Maps_headerMaps__XxFA+`,
	"tituloMaps": `Maps_tituloMaps__0yqx7`,
	"filtro": `Maps_filtro__xJHj+`,
	"map": `Maps_map__+DQOd`,
	"mapContainer": `Maps_mapContainer__rIUTF`
};
export default ___CSS_LOADER_EXPORT___;
