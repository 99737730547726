import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import styles from "./Login.module.css";

import LoginForm from "./LoginForm";
import LoginCreate from "./LoginCreate";
import { UserContext } from "../../UserContext";
import Pagina404 from "../Pagina404";
import Header from "../Header";

const Login = () => {
  const { login } = useContext(UserContext);

  if (login) return <Navigate to="/mapa" />;

  return (
    <section>
      <Header />
      <div className={styles.login}>
        <div className={styles.forms}>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/criar" element={<LoginCreate />} />
            <Route path="/*" element={<Pagina404 />} />
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default Login;
