import React from "react";
import { useNavigate } from "react-router-dom";
import logoPng from "../assets/logo-new.png"

const Header = ({children}) => {
  const navigate = useNavigate();

  async function handleClick(event) {
    event.preventDefault();
    navigate("/");
  }
  
  return (
  <header className="header">
    <img src={logoPng} alt="" width="186px" height="90px" onClick={handleClick}>
    </img>

    {children}
  </header>
  );
};

export default Header;