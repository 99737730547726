import React, { useContext } from "react";
import { POST_USER } from "../../api";
import useFetch from "../Hooks/useFetch";
import useForm from "../Hooks/useForm";
import { UserContext } from "../../UserContext";
import Button from "../Forms/Button";
import Input from "../Forms/Input";
import { Erro } from "../Erro";
import Head from "../Head";
import { Link } from "react-router-dom";

import stylesForm from "./LoginForm.module.css";
import styles from "./LoginCreate.module.css";

const LoginCreate = () => {
  const username = useForm();
  const email = useForm("email");
  const password = useForm("password");

  const { userLogin } = useContext(UserContext);
  const { loading, erro, request } = useFetch();

  async function handleSubmit(event) {
    event.preventDefault();
    const { url, options } = POST_USER({
      username: username.value,
      email: email.value,
      password: password.value,
    });

    const { response } = await request(url, options);
    if (response.ok) userLogin(username.value, password.value);
  }

  return (
    <section className={`card ${styles.cardFlex}`}>
      <Head title="Criar Conta" description="Login UpSec." />

      <h1 className="titulo">Cadastre-se</h1>
      <form onSubmit={handleSubmit}>
        <Input label="Usuario" type="text" name="username" {...username}/>
        <Input label="Email" type="email" name="email" {...email} />
        <Input label="Senha" type="password" name="password" {...password} />
        <Input label="Nome" type="text" name="nome" />
        <Input label="Profissão" type="text" name="Profissão" />
        <Input label="Empresa" type="text" name="Empresa" />
        <Input label="Telefone" type="text" name="Telefone" />
    
      
        <div className={styles.aviso}>
          <i>Ao se cadastrar você concorda em receber conteúdos sobre divulgação, promoção, conteúdos técnicos, eventos, newsletter, emails marketing e outras iniciativas relacionadas ao mercado de construção civil, por email.</i>   
        </div>
        <div className={styles.redirect}>
          <p>Já tem uma conta?</p>
          <Link className={stylesForm.esqueceuSenha} to="/login">
            Fazer login.
          </Link>
        </div>
        
        {loading ? (
          <Button disabled>Cadastrando...</Button>
        ) : (
          <Button>Cadastrar</Button>
        )}
        <Erro erro={erro} />
        
      </form>

    </section>
  );
};

export default LoginCreate;
