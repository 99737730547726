import React, { useEffect, useState, useContext } from "react";
import styles from "./Lista.module.css";
import {UserContext} from "../../UserContext";

const Lista = () => {
  const { filter } = useContext(UserContext);
  const [listaJSON, setListaJSON] = useState([]);

  useEffect(() => {
    fetch(`./${filter}_table.json`, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => setListaJSON(res.cidades));
  }, [filter]);

  return (
    <div className={styles.wrap}>
      <div className={styles.tableWrapper}>
        <table className={`${styles.tableResponsive} ${styles.cardListTable}`}>
          <thead>
            <tr>
              <th >Razão Social</th>
              <th >Tipo</th>
              <th >E-mail</th>
              <th >Localidade</th>
            </tr>
          </thead>

          <tbody>
            {listaJSON.map((item, index) => (
              <tr key={index}>
                <td data-title="Razão Social">{item.name}</td>
                <td data-title="Tipo">{item.ramo}</td>
                <td data-title="E-mail">{item.x}</td>
                <td data-title="Localidade">{item.y}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Lista;
