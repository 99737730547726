import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import styles from "./Maps.module.css";
import {
  MapContainer,
  TileLayer, 
  Popup,
  Marker,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import { Icon } from "leaflet";

import "leaflet/dist/leaflet.css";

const Maps = () => {

  const { setFilter, unidades, filter } = useContext(UserContext);
  const center = {
    lat: -15.688985752265351,
    lng: -56.06207014657331,
  };
  const customIcon = new Icon({
    iconUrl: require("../../assets/marker-24.png"),
    iconSize: [22, 20],
  });

  return (
    <section>
      <div className={styles.headerMaps}>
        <h1 className={`subtitulo ${styles.tituloMaps}`}>Conexão Construtiva</h1>
        <select
          className={styles.filtro}
          name="tipos"
          value={filter}
          onChange={(valorTexto) => setFilter(valorTexto.target.value)}
        >
          <option value="full">Todas conexões</option>
          <option value="construtoras">Construtoras</option>
          <option value="steelframe">Steel Frame</option>
          <option value="instaladores">Instaladores </option>
          <option value="distribuidores">Distribuidores</option>          
          <option value="locacao">Locação de equipamentos</option>
          <option value="Impermeabilizacao">Impermeabilização</option>
          <option value="incendio">Instalação de sistema contra incêndio</option>
          <option value="incorporadoras">Incorporadoras</option>
          <option value="tratamentos_termicos">Tratamentos térmicos, acusticos, vibração</option>
          <option value="transporte">Transporte e elevação de cargas</option>

        </select>
      </div>

      <div className={styles.map}>
        <MapContainer className={styles.mapContainer}
          center={center}
          zoom={4}
          maxZoom={10}
          minZoom={4}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {unidades.map((item, index) => (
              <span key={index}>
                <Marker position={item.position} icon={customIcon}>
                  <Popup>{item.unidades.join("; ")}</Popup>
                </Marker>

                <CircleMarker
                  center={item.position}
                  pathOptions={{ color: "#c2e069" }}
                  radius={20}
                  stroke={false}
                >
                  <Tooltip>{item.name}</Tooltip>
                </CircleMarker>
              </span>

            ))}
        </MapContainer>
      </div>

    </section>
  );
};

export default Maps;
