import React from "react";
import styles from "./Body.module.css";

const Section1 = () => {
  return (
    <div className={styles.bodyContainer}>

      <section className={styles.bodySection}>
        <h2 className={styles.bodyTitulo}>Bem vindo a Conexão Construtiva.</h2>
        <p>
          Somos uma plataforma que integra a cadeia de construção a seco em um
          único lugar. Nosso objetivo é possibilitar conexões que impulsionem e
          potencializem seu negócio.{" "}
        </p>
        <a href="/">
          Venha fazer parte desta conexão
        </a>
      </section>

    </div>
  );
};

export default Section1;
