import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import styles from "./Profile.module.css";

const Profile = () => {
  const { data } = useContext(UserContext);

  let dataKeys = Object.entries(data);

  return (
    <div className={styles.profile}>
        <h2 className={`subtitulo ${styles.h2}`}>Olá, <span className={styles.nomeUser}>{data.nome}</span></h2>
        {dataKeys.map((item) => {
          return (
            <ul className={styles.lista} key={item}>
              <li className={styles.item0} key={item[0]}>{`${item[0]}: `}</li>
              <li className={styles.item1} key={item[1]}>
                {` ${item[1]}`}{" "}
              </li>
            </ul>
          );
        })}
        
    </div>
  );
};

export default Profile;
