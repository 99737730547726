// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__4rE5\\+ {
  padding: 2rem 8rem 4rem 8rem;
}

@media screen and (max-width: 1200px) and (min-width: 601px){
  .Login_login__4rE5\\+ {
    padding: 2rem 4rem 4rem 4rem;
  }
}

@media screen and (max-width: 600px) {
  .Login_login__4rE5\\+ {
    padding: 2rem .5rem 2rem .5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".login {\n  padding: 2rem 8rem 4rem 8rem;\n}\n\n@media screen and (max-width: 1200px) and (min-width: 601px){\n  .login {\n    padding: 2rem 4rem 4rem 4rem;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .login {\n    padding: 2rem .5rem 2rem .5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__4rE5+`
};
export default ___CSS_LOADER_EXPORT___;
