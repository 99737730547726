import React from "react";
import Head from "./Head";
import Button from "./Forms/Button";
import { useNavigate } from "react-router-dom";

const Pagina404 = () => {
  const navigate = useNavigate();

  async function handleClick(event) {
    event.preventDefault();
    navigate("/");
  }

  return (
    <div className="page404">
      <Head title="404" description="Página não encontrada - UpSec" />

      <h1 className="titulo">404</h1>
      <h2>Page Not Found</h2>
      <Button onClick={handleClick} className="botao404">Back Home </Button>
    </div>
  );
};

export default Pagina404;
