import React from "react";
import Head from '../Head';
import Section1 from "./Section1";
import SectionCard from './SectionCard';
import Footer from '../Footer';
import SectionMapa from "./SectionMapa";

const Body = () => {
  return (
    <div className="bodyLP">
        <Head title="Início" description="" />

        <Section1/>
        <SectionCard/>
        <SectionMapa/>

        <Footer/>
    </div>
  );
};

export default Body;
