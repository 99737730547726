import React from "react";
import { Route, Routes } from "react-router-dom";
import Pagina404 from "../Pagina404";
import Body from "./Body";
import HeaderLP from "./HeaderLP";

const LandingPage = () => {
  return (
    <div>
      <HeaderLP />

      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/*" element={<Pagina404 />} />
      </Routes>
    </div>
  );
};

export default LandingPage;
