import "./App.css";
import { UserStorage } from "./UserContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Home from "./components/Dashboard/Home";
import ProtectedRoute from "./ProtectedRoute";
import LandingPage from "./components/LandingPage/LandingPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <UserStorage>
          <main>
            <Routes>
            <Route path="/*" element={<LandingPage />} />

              <Route path="/login/*" element={<Login />} />

              <Route
                path="/mapa"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
          
        </UserStorage>
      </BrowserRouter>
    </div>
  );
}

export default App;
